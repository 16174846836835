import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import axios from "axios";
import {
  register,
  clearRegistrationSuccess,
  clearError,
} from "../../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleLoginButton from "../../../pages/Register/GoogleLoginButton";
function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [responseError, setResponseError] = useState(null);
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [touched, setTouched] = useState({
    // username: false,
    email: false,
    password: false,
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, registrationSuccess, isAuthenticated, errorType } =
    useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = useState(false); // State to handle modal visibility

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle modal open/close
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mark all fields as touched
    setTouched({
      // username: true,
      email: true,
      password: true,
    });

    // Validate both fields
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    const usernameError = validateUsername(username);

    setErrors({
      username: usernameError,
      email: emailError,
      password: passwordError,
    });

    if (!emailError && !passwordError) {
      // Handle form submission
      await dispatch(register({ username, email, password }));
    }
  };

  useEffect(() => {
    if (registrationSuccess) {
      navigate("/select-banks");
      dispatch(clearRegistrationSuccess());
    } else {
      setResponseError(error);
    }
  }, [registrationSuccess, navigate, dispatch, handleSubmit]);

  useEffect(() => {
    // Clear error on component mount
    dispatch(clearError());
    setEmail(""); // Clear email on component mount
    setPassword(""); // Clear password on component mount
  }, [dispatch, isOpen]);

  const validateEmail = (email) => {
    if (!email.trim()) {
      return "Email is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    if (name === "password") {
      setPassword(value);
    }

    if (name === "username") {
      setUsername(value);
    }

    dispatch(clearError());

    if (touched[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]:
          name === "email"
            ? validateEmail(value)
            : name === "username"
            ? validateUsername(value)
            : name === "password"
            ? validatePassword(value)
            : "",
      }));
    }
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]:
        name === "email"
          ? validateEmail(email)
          : name === "password"
          ? validatePassword(password)
          : validateUsername(username),
    }));
  };

  const validatePassword = (value) => {
    // Check for uppercase
    if (!/[A-Z]/.test(value)) {
      return "Password must contain at least one uppercase character";
    }

    // Check for number
    if (!/[0-9]/.test(value)) {
      return "Password must contain at least one number";
    }

    // Check for minimum length
    if (value.length < 8) {
      return "Password must be at least 8 characters long";
    }

    // Check for special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return "Password must contain at least one special character";
    }

    return "";
  };

  const validateUsername = (username) => {
    return !username.trim() ? "Username is required" : "";
  };

  return (
    <>
      <div>
        {" "}
        <button
          className="inline-flex items-center py-2.5 px-4 mt-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 D1:focus:ring-primary-900 hover:bg-primary-800"
          type="button"
          onClick={toggleModal}
        >
          Sign up
        </button>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={toggleModal} // Close when clicking outside modal>
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full p-6 bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8"
          >
            <div className="flex justify-center">
              <h1 className="text-2xl xl:text-3xl font-extrabold ">
                Create an account
              </h1>
              <button
                type="button"
                className="ml-10 text-gray-400  bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 D:hover:bg-gray-600 D:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="">
              <GoogleLoginButton setResponseError={setResponseError} />
            </div>
            <form onSubmit={handleSubmit} className="w-full flex-1 mt-8">
              {/* <div className="flex flex-col items-center">
                  <button className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
                    <div className="bg-white p-2 rounded-full">
                      <svg className="w-4" viewBox="0 0 533.5 544.3">
                        <path
                          d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                          fill="#4285f4"
                        />
                        <path
                          d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                          fill="#34a853"
                        />
                        <path
                          d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                          fill="#fbbc04"
                        />
                        <path
                          d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                          fill="#ea4335"
                        />
                      </svg>
                    </div>
                    <span className="ml-4">Sign Up with Google</span>
    
                   
                  </button>
    
                  
                </div> */}

              <div className="my-10 mt-1 border-b text-center">
                <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                  Or sign up with e-mail
                </div>
              </div>

              <div className="mx-auto max-w-xs">
                {/* <input
                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Full Name"
                  /> */}

                {/* <div className="relative mb-0">
                  <input
                    className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                      touched.username && errors.username
                        ? "border-red-500"
                        : "border-gray-200"
                    } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5`}
                    type="text"
                    onChange={handleChange}
                    value={username}
                    placeholder="username"
                    name="username"
                    required
                    onBlur={handleBlur}
                  />
                  <p
                    id="standard_error_help"
                    class="mt-2 text-xs text-red-600 D1:text-red-400"
                  >
                    {errors.username}
                  </p>
                </div> */}
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                    touched.email && errors.email
                      ? "border-red-500"
                      : "border-gray-200"
                  } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5`}
                  type="email"
                  onChange={handleChange}
                  value={email}
                  placeholder="Email"
                  name="email"
                  required
                  onBlur={handleBlur}
                />
                <p
                  id="standard_error_help"
                  class="mt-2 text-xs text-red-600 D1:text-red-400"
                >
                  {errors.email}
                </p>
                <div className="relative mt-5">
                  <input
                    className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                      touched.password && errors.password
                        ? "border-red-500"
                        : "border-gray-200"
                    } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    placeholder="Password"
                    value={password}
                    required
                    name="password"
                    onBlur={handleBlur}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOffTwoToneIcon />
                    ) : (
                      <VisibilityTwoToneIcon />
                    )}
                  </button>
                </div>
                <p
                  id="standard_error_help"
                  class="mt-2 text-xs text-red-600 D1:text-red-400"
                >
                  {errors.password || (errorType !== "google_error" && error)}
                </p>
                {/* <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                    /> */}
                {/* <p
                    id="standard_error_help"
                    class="mt-2 text-xs text-red-600 D1:text-red-400"
                  >
                    {error || responseError}
                  </p> */}
                <div className="space-y-1 text-sm text-gray-500">
                  <p className="text-sm mt-5">Password must contain:</p>
                  <ul className="list-disc list-inside space-y-1">
                    <li
                      className={
                        password.match(/[A-Z]/) ? "text-green-600" : ""
                      }
                    >
                      One uppercase character
                    </li>
                    <li
                      className={
                        password.match(/[0-9]/) ? "text-green-600" : ""
                      }
                    >
                      One number
                    </li>
                    <li
                      className={password.length >= 8 ? "text-green-600" : ""}
                    >
                      Minimum 8 characters
                    </li>
                    <li
                      className={
                        password.match(/[!@#$%^&*(),.?":{}|<>]/)
                          ? "text-green-600"
                          : ""
                      }
                    >
                      One special character
                    </li>
                  </ul>
                </div>

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={
                    "mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none bg-blue-700 text-gray-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
                  }
                >
                  {loading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      Signing...
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      <span className="ml-3">Sign Up</span>
                    </>
                  )}
                </button>
                {/* <p className="mt-6 text-xs text-gray-600 text-center">
                      I agree to abide by templatana's
                      <a
                        href="#"
                        className="border-b border-gray-500 border-dotted"
                      >
                        Terms of Service
                      </a>
                      and its
                      <a
                        href="#"
                        className="border-b border-gray-500 border-dotted"
                      >
                        Privacy Policy
                      </a>
                    </p> */}
                {/* <p class=" mt-4 text-sm font-light text-gray-500 D1:text-gray-400">
                  Already have an account?{" "}
                  <Link
                    to={"/signin"}
                    className="  font-medium text-primary-600 hover:underline D1:text-primary-500"
                  >
                    Login here.
                  </Link>
                </p> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default SignUp;

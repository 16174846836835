import React, { useState, useEffect } from "react";
import { Trash2, Plus as PlusCircle, Upload } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import BankSelectionModal from "../components/blog/lib/SelectBanks";
import { getUserDetails } from "../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import { updateBankPreferences } from "../components/blog/lib/bankPreferences";

const ProductList = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = "https://backend.wanij.com/";

  const bankPreferences = useSelector(
    (state) => state.auth.user?.bank_preferences
  );
  const email = useSelector((state) => state.auth.user?.email);
  const isLoading = useSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (bankPreferences) {
        const formattedBanks = Object.values(bankPreferences).map((bank) => ({
          name: bank.name,
          bref: bank.bref,
          bcode: bank.bcode,
          type: bank.type,
          image: `../bankLogos/${bank.bcode}.png`,
        }));
        setSelectedBanks(formattedBanks);
        setError(null);
      }
    } catch (err) {
      console.error("Error processing bank preferences:", err);
      setError("Failed to load bank preferences");
    }
  }, [bankPreferences]);

  const remainingBanks = 5 - selectedBanks.length;

  const handleUpdatePreferences = async (updatedBanks) => {
    try {
      await updateBankPreferences({
        selectedBanks: updatedBanks,
        email,
        apiUrl: API_URL,
        onSuccess: async () => {
          const userDetails = await dispatch(getUserDetails());
          setError(null);
        },
        onError: (error) => setError(error),
        onLoadingChange: (isLoading) => setLoading(isLoading),
      });
    } catch (err) {
      console.error("Error updating preferences:", err);
      setError("Failed to update bank preferences");
    }
  };

  const removeProduct = async (id) => {
    try {
      setLoading(true);
      const updatedBanks = selectedBanks.filter((bank) => bank.bcode !== id);
      setSelectedBanks(updatedBanks);
      await handleUpdatePreferences(updatedBanks);
    } catch (err) {
      console.error("Error removing bank:", err);
      setError("Failed to remove bank");
      setSelectedBanks(selectedBanks);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full max-w-2xl mx-auto p-4">
        <div className="text-center py-8 text-gray-500">
          Loading bank preferences...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full max-w-2xl mx-auto p-4">
        <div className="text-center py-8 text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Selected Banks List</h2>
          <button
            onClick={() => setShowAddForm(!showAddForm)}
            className="flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={remainingBanks === 0}
            style={{
              backgroundColor: remainingBanks === 0 ? "#CBD5E1" : "#3B82F6",
              color: "white",
            }}
          >
            <PlusCircle className="w-4 h-4" />
            Add Bank
          </button>
        </div>

        <div className="text-sm text-gray-600">
          {remainingBanks > 0 ? (
            <span>
              You can add {remainingBanks} more bank
              {remainingBanks !== 1 ? "s" : ""}
            </span>
          ) : (
            <span className="text-yellow-600">
              Maximum number of banks (5) selected
            </span>
          )}
        </div>
      </div>

      {showAddForm && <BankSelectionModal />}

      <div className="mt-4">
        {selectedBanks.length > 0 ? (
          selectedBanks.map((product) => (
            <div
              key={product.bref}
              className="flex items-center justify-between py-3 border-b"
            >
              <div className="flex items-center gap-3">
                {product.image ? (
                  <div className="w-8 h-8 flex-shrink-0">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-full object-contain rounded"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "path/to/fallback-image.png";
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-8 h-8 bg-gray-200 rounded flex items-center justify-center flex-shrink-0">
                    <Upload className="w-4 h-4 text-gray-400" />
                  </div>
                )}
                <span className="text-base font-medium">{product.name}</span>
              </div>

              <button
                onClick={() => removeProduct(product.bcode)}
                className="flex items-center gap-2 text-red-500 hover:text-red-600 text-sm"
                disabled={loading}
              >
                <Trash2 className="w-4 h-4" />
                <span>Remove</span>
              </button>
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-500">
            No banks selected. Click "Add Bank" to select banks.
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;

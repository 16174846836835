import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleLogin } from "../../redux/slice/authSlice";
import { useEffect, useState } from "react";

function GoogleLoginButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { error, isAuthenticated, user, errorType } = useSelector(
    (state) => state.auth
  );
  const clientId =
    "1049121200498-mnfho130h5k9j0lkeosses63vq9fra6r.apps.googleusercontent.com";

  const handleGoogleLogin = async (googleResponse) => {
    // console.log("googleResponse", googleResponse);
    const credential = googleResponse.credential;
    await dispatch(googleLogin(credential));
    if (isAuthenticated) {
      navigate("/");
    } else {
      console.log("tokens", localStorage.getItem("tokens"));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate, isAuthenticated]);

  return (
    <div className="flex flex-col justify-center  w-full px-8 py-4 pb-0">
      <GoogleLogin
        onSuccess={handleGoogleLogin}
        clientId={clientId}
        onFailure={() => console.log("Google Login Failed")}
        cookityPolicy={"single_host_origin"}
        onError={() => console.log("Google Login Failed")}
        size="large"
        type="standard"
        theme="filled_blue"
        width={`${isMobile ? "300" : "320"}`}
      />
      <p
        id="standard_error_help"
        className="mt-2 text-xs text-red-600 D1:text-red-400"
      >
        {errorType === "google_error" && error}
      </p>
      <style jsx>{`
        .w-full .google-login-button {
          width: 100%;
          max-width: 320px; /* Set a consistent max width */
          height: 50px; /* Enforce consistent height */
          overflow: hidden; /* Avoid size jumping issues */
        }
      `}</style>
    </div>
  );
}

export default GoogleLoginButton;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@nextui-org/react";
import numberstowords from "@rajch/numberstowords";
import { SearchIcon } from "../../components/icons/SearchIcon";
import ErrorPage from "../../components/ErrorPage";
import styles from "../SearchBar/searchbar.module.css";
import {
  setAmount,
  fetchOtherData,
} from "../../redux/slice/savingsFullRatesSlice";
import { TrackGoogleAnalyticsEvent } from "../../utils/googleAnalytics/googleAnalytics";

function SearchBar() {
  const dispatch = useDispatch();
  const Amount = useSelector((state) => state.savingsFullRate.Amount);

  const [formattedAmount, setFormattedAmount] = useState(
    Number(Amount).toLocaleString("en-IN")
  );
  const [errors, setErrors] = useState({
    zero: false,
    max: false,
    decimal: false,
    invalid: false,
  });
  const [errPage, setErrPage] = useState(false);
  const [showBankList, setShowBankList] = useState(false);

  // Memoize the validation logic
  const validateAmount = useCallback((value) => {
    const newErrors = {
      zero: false,
      max: false,
      decimal: false,
      invalid: false,
    };

    if (Number(value) === 0) {
      newErrors.zero = true;
      return { isValid: false, errors: newErrors };
    }

    if (!/^\d+$/.test(value)) {
      if (value.includes(".")) {
        newErrors.decimal = true;
      } else {
        newErrors.invalid = true;
      }
      return { isValid: false, errors: newErrors };
    }

    const numValue = Number(value);
    if (numValue > 100000000) {
      newErrors.max = true;
      return { isValid: false, errors: newErrors };
    }

    return { isValid: true, errors: newErrors };
  }, []);

  // Memoize the search trigger function
  const triggerSearch = useCallback(() => {
    setShowBankList(true);
    dispatch(fetchOtherData());
    TrackGoogleAnalyticsEvent("Search", "Amount Search Submit", "user search");
  }, [dispatch]);

  // Handle input change
  const handleInput = useCallback(
    (e) => {
      const value = e.target.value.replace(/,/g, "");

      const { isValid, errors: newErrors } = validateAmount(value);
      setErrors(newErrors);

      if (isValid) {
        const numValue = Number(value);
        setFormattedAmount(numValue.toLocaleString("en-IN"));
        dispatch(setAmount(numValue));
      } else {
        setFormattedAmount(value);
      }
    },
    [dispatch, validateAmount]
  );

  // Handle form submission
  const handleSubmit = useCallback(
    (event) => {
      event?.preventDefault();
      if (!errors.zero && !errors.max && !errors.decimal && !errors.invalid) {
        triggerSearch();
      }
    },
    [errors, triggerSearch]
  );

  // Handle tab out
  const handleBlur = useCallback(() => {
    if (!errors.zero && !errors.max && !errors.decimal && !errors.invalid) {
      triggerSearch();
    }
  }, [errors, triggerSearch]);

  // Initial data fetch
  useEffect(() => {
    triggerSearch();
  }, [triggerSearch]);

  if (errPage) return <ErrorPage />;

  return (
    <div className={styles.searchContainer}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperSearch}>
          <form onSubmit={handleSubmit} className={styles.searchBar}>
            <Input
              classNames={{
                inputWrapper: styles.searchQueryInput,
                input: [styles.input],
              }}
              placeholder="Savings Amount"
              labelPlacement="outside"
              startContent={
                <button onClick={handleSubmit} type="submit">
                  <SearchIcon
                    className={`flex-shrink-0 ${styles.searchIcon}`}
                  />
                </button>
              }
              value={formattedAmount}
              onChange={handleInput}
              onBlur={handleBlur}
              endContent={
                <div
                  className={`pointer-events-none flex items-right ${styles.endContent}`}
                >
                  <span className="text-default-400 text-small ml-2">
                    Enter an amount
                  </span>
                </div>
              }
            />
          </form>

          {errors.zero && (
            <span className={styles.errorMsg}>
              *Please enter amount greater than zero
            </span>
          )}
          {errors.max && (
            <span className={styles.errorMsg}>
              *Please enter amount less than 100000000
            </span>
          )}
          {errors.decimal && (
            <span className={styles.errorMsg}>
              *Please enter whole numbers only
            </span>
          )}
          {errors.invalid && (
            <span className={styles.errorMsg}>
              *Please enter a valid number
            </span>
          )}

          {!Object.values(errors).some(Boolean) && Amount !== 0 && (
            <p className={styles.numberInWords}>
              {numberstowords.toIndianWords(Amount, { useCase: "proper" })}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchBar;

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slice/authSlice";
import GoogleLoginButton from "../../../pages/Register/GoogleLoginButton";
import { clearError } from "../../../redux/slice/authSlice";

function LoginPopup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { loading, error, isAuthenticated, errorType } = useSelector(
    (state) => state.auth
  );

  const [isOpen, setIsOpen] = useState(false); // State to handle modal visibility

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle modal open/close
  };

  //
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(login({ email, password }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }

    dispatch(clearError());
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, [handleSubmit, navigate, isAuthenticated, error, dispatch]);

  useEffect(() => {
    // Clear error on component mount
    dispatch(clearError());
    setEmail(""); // Clear email on component mount
    setPassword(""); // Clear password on component mount
  }, [dispatch, isOpen]);

  return (
    <>
      <div>
        {" "}
        <button
          className="inline-flex items-center py-2.5 px-4 mt-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 D1:focus:ring-primary-900 hover:bg-primary-800"
          type="button"
          onClick={toggleModal}
        >
          Sign in
        </button>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={toggleModal} // Close when clicking outside modal
        >
          {/* Modal content */}
          <div
            className="relative bg-white rounded-lg shadow-lg D:bg-gray-700 p-6 max-w-md w-full"
            onClick={(e) => e.stopPropagation()} // Prevent click propagation to parent
          >
            {/* Modal header */}
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-xl font-semibold text-gray-900 D:text-white">
                Sign in to our platform
              </h1>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 D:hover:bg-gray-600 D:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {/* Modal body */}
            <form className="space-y-4">
              <div className="px-1 py-2">
                <GoogleLoginButton />
              </div>
              <div className="my-5 border-b text-center">
                <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                  Or sign In with e-mail
                </div>
              </div>

              <div className="mx-auto max-w-xs">
                <input
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                  type="email"
                  onChange={handleChange}
                  placeholder="Email"
                  name="email"
                  required
                />
                <div className="relative mt-5 mb-2">
                  <input
                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    placeholder="Password"
                    name="password"
                    value={password}
                    required
                  />

                  <button
                    type="button"
                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <VisibilityOffTwoToneIcon />
                    ) : (
                      <VisibilityTwoToneIcon />
                    )}
                  </button>
                </div>
                <p
                  id="standard_error_help"
                  class="mt-2 text-xs text-red-600 D1:text-red-400"
                >
                  {errorType !== "google_error" && error}
                </p>
                <Link
                  to={"/forgot-password"}
                  className="text-sm  font-medium text-primary-600 hover:underline D1:text-primary-500"
                >
                  Forgot password?
                </Link>

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={
                    "mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none bg-blue-700 text-gray-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
                  }
                >
                  {loading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      Signing...
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      <span className="ml-3">Sign In</span>
                    </>
                  )}
                </button>

                {/* <p class=" mt-4 text-sm font-light text-gray-500 D1:text-gray-400">
                  Not registered?{" "}
                  <Link
                    to={"/signup"}
                    className="  font-medium text-primary-600 hover:underline D1:text-primary-500"
                  >
                    Create an account.
                  </Link>
                </p> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPopup;

import React from "react";
import { shareOnMobile } from "react-mobile-share";

function ShareOnMobile() {
  const url = window.location.href;
  const handleShareOnMobile = () => {
    shareOnMobile({ title: "Wanij Blog Share", url: url });
    //
  };
  return (
    <div>
      <button onClick={handleShareOnMobile}>
        <div className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-share"
          >
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
            <polyline points="16 6 12 2 8 6" />
            <line x1="12" x2="12" y1="2" y2="15" />
          </svg>
        </div>
      </button>
    </div>
  );
}

export default ShareOnMobile;

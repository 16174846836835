import React, { useState } from "react";

const EmailInput = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleBlur = (event) => {
    const input = event.target;

    // Use the built-in validity object to check validity
    if (!input.validity.valid) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default EmailInput;

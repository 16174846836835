import axios from "axios";

export const updateBankPreferences = async ({
  selectedBanks,
  email,
  apiUrl,
  onSuccess = () => {},
  onError = () => {},
  onLoadingChange = () => {},
}) => {
  try {
    onLoadingChange(true);

    // Convert selected banks array to object format expected by the API
    const selectedBanksObject = selectedBanks.map((bank) => bank.bref);
    console.log("selectedBanksObject", selectedBanksObject);
    const response = await axios.post(`${apiUrl}bank-preferences/`, {
      banks: selectedBanksObject,
      email: email,
    });

    onLoadingChange(false);
    onSuccess(response.data);

    return response.data;
  } catch (err) {
    const errorMessage =
      err.response?.data?.error || "Failed to update preferences";
    onError(errorMessage);
    onLoadingChange(false);
    console.error("Error:", err);
    throw err;
  }
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../Spinners/Spinner";
import styles from "./comment.module.css";
import LoginPopup from "./LoginPopup";
import SignUpPopup from "./SignUpPopup";
import { apiRequest } from "../../../utils/apiRequest";
import UpdateUsernameForm from "./UpdateProfile";

const Comments = ({ postId, handleCommentCount, setCommentCount }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState({ content: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);
  const [replyTo, setReplyTo] = useState(null); // Stores the ID of the comment being replied to
  const {
    error: authError,
    isAuthenticated,
    user,
  } = useSelector((state) => state.auth);

  // Resend email login starts form here.
  const [message, setMessage] = useState("");
  const [errorResendEmail, setResendError] = useState("");
  const [loading, setLoading] = useState(false);
  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const handleResendEmail = (e) => {
    apiRequest({
      url: `${API_URL}/resend-verification/`,
      data: { email: user.email },
      onSuccess: setMessage,
      onError: setError,
      setLoading,
    });
  };

  useEffect(() => {
    fetchComments();
  }, [postId]);

  const fetchComments = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://wp.wanij.com/wordpress/index.php?rest_route=%2Fwp%2Fv2%2Fcomments&post=${postId}`
      );
      if (!response.ok) throw new Error("Failed to fetch comments");
      const data = await response.json();
      const structuredComments = structureComments(data);
      setComments(structuredComments);
      setCommentCount(data.length);
    } catch (err) {
      setError("Error fetching comments. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const structureComments = (commentsData) => {
    const commentMap = {};
    const rootComments = [];

    commentsData.forEach((comment) => {
      commentMap[comment.id] = { ...comment, replies: [] };
    });

    commentsData.forEach((comment) => {
      if (comment.parent === 0) {
        rootComments.push(commentMap[comment.id]);
      } else {
        const parentComment = commentMap[comment.parent];
        if (parentComment) {
          parentComment.replies.push(commentMap[comment.id]);
        }
      }
    });

    return rootComments;
  };

  const postWordPressComment = async (
    commentData,
    apiUrl,
    username,
    applicationPassword
  ) => {
    const authHeader = "Basic " + btoa(username + ":" + applicationPassword);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        body: JSON.stringify(commentData),
      });

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${errorBody}`
        );
      }

      const result = await response.json();

      return result;
    } catch (error) {
      console.error("Error posting comment:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const commentData = {
        post: postId,
        author_name: user?.username,
        author_email: user?.email,
        content: newComment.content,
        parent: replyTo,
      };

      await new Promise((resolve) => setTimeout(resolve, 500));

      const postedComment = await postWordPressComment(
        commentData,
        "https://wp.wanij.com/wordpress/index.php?rest_route=%2Fwp%2Fv2%2Fcomments",
        "wanij",
        "NzH7 Qnod YudJ YezI OV0a L8jp"
      );

      setNewComment({ author: "", content: "" });
      setReplyTo(null);
      fetchComments();
    } catch (err) {
      if (err.message.includes("409")) {
        setError(
          "This comment appears to be a duplicate. Please try a different comment."
        );
      } else {
        setError("Error posting comment. Please try again.");
      }
      console.error(err);
    }
  };

  const handleTouched = (e) => {
    setTouched(true);
    setNewComment({ ...newComment, content: e.target.value });
  };

  const emailVerificationWarning = ({ color, text }) => (
    <div
      class={`flex items-center p-2.5 mb-4  mt-8 text-sm ${color} D:bg-gray-800 D:text-yellow-300 D:border-yellow-800`}
      role="alert"
    >
      <svg
        class="flex-shrink-0 inline w-4 h-4 me-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        {message ? null : text}
        <span class="font-medium">
          {" "}
          {loading ? (
            <button
              disabled
              type="button"
              class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 D:bg-gray-800 D:text-gray-400 D:border-gray-600 D:hover:text-white D:hover:bg-gray-700 inline-flex items-center"
            >
              <svg
                aria-hidden="true"
                role="status"
                class="inline w-4 h-4 me-3 text-gray-200 animate-spin D:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#1C64F2"
                />
              </svg>
              Sending...
            </button>
          ) : message ? (
            message
          ) : (
            <button
              onClick={handleResendEmail}
              disabled={loading}
              className="border-b border-blue-600"
            >
              Resend verification email!
            </button>
          )}
        </span>
      </div>
    </div>
  );

  const renderReplyForm = () => (
    <form className="mb-6 " onSubmit={handleSubmit}>
      <div className="py-2 pb-4 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 D1:bg-gray-800 D1:border-gray-700">
        {/* <div>
          <label
            htmlFor="author"
            className="block mb-2 text-sm font-medium text-gray-900 D1:text-white"
          >
            Name
          </label>
          <input
            type="text"
            id="author"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 D1:bg-gray-700 D1:border-gray-600 D1:placeholder-gray-400 D1:text-white D1:focus:ring-blue-500 D1:focus:border-blue-500"
            placeholder="Enter your name"
            required
            value={newComment.author}
            onChange={(e) =>
              setNewComment({ ...newComment, author: e.target.value })
            }
          />
        </div> */}
        <label
          htmlFor="content"
          className="block mb-2 mt-4 text-sm font-medium text-gray-900 D1:text-white"
        >
          {replyTo ? "Your reply" : "Your comment"}
        </label>
        <textarea
          id="content"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 D1:bg-gray-700 D1:border-gray-600 D1:placeholder-gray-400 D1:text-white D1:focus:ring-blue-500 D1:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          value={newComment.content}
          onChange={handleTouched}
          required
        ></textarea>
        <div className="flex items-center ">
          {isAuthenticated ? (
            <div className="flex items-center">
              <button
                disabled={
                  user?.is_active === false || (!user?.username && touched)
                }
                type="submit"
                className={`mr-4 ${
                  user?.is_active === false || (!user?.username && touched)
                    ? "text-white bg-blue-400 D:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 mt-4 py-2.5 text-center"
                    : "inline-flex items-center py-2.5 px-4 mt-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 D1:focus:ring-primary-900 hover:bg-primary-800"
                }`}
              >
                {replyTo ? "Post reply" : "Post comment"}
              </button>
              {user?.is_active === false && touched ? (
                <>
                  {emailVerificationWarning({
                    color:
                      "text-red-800 border border-red-300 rounded-lg bg-red-50",
                    text: "Please verify your email before commenting.",
                  })}
                </>
              ) : !user?.username && touched ? (
                <div
                  class="flex items-center p-2 mb-4 ml-4 mt-8 text-md text-red-800 border border-red-300 rounded-lg bg-red-50 D:bg-gray-800 D:text-red-400 D:border-red-800"
                  role="alert"
                >
                  <svg
                    class="flex-shrink-0 inline w-4 h-4 me-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span class="sr-only">Info</span>
                  <div>
                    <span class="font-medium">
                      Set your username to continue post comments.
                    </span>
                    .
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <div className="flex items-center ">
                {" "}
                <LoginPopup /> <p className="mt-8 mx-4">or</p> <SignUpPopup />
                <p className="mt-8 mx-4">to post comments.</p>
              </div>
            </>
          )}
          {replyTo && (
            <button
              type="button"
              className="inline-flex items-center py-2.5 px-4 mt-4 ml-2 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-4 focus:ring-primary-200 D1:focus:ring-gray-800 hover:bg-gray-100"
              onClick={() => setReplyTo(null)}
            >
              Cancel reply
            </button>
          )}
        </div>
      </div>
    </form>
  );

  // const renderLoginPopup = () => (

  // );

  const renderComment = (comment) => (
    <article
      key={comment.id}
      className={`p-6 pb-2  px-4 text-base bg-white rounded-lg rounded-t-lg ${
        comment.parent === 0 ? "border border-gray-200 " : "pb-0"
      } mb-6 D1:bg-gray-900`}
    >
      <footer className="flex justify-between items-center mb-2">
        <div class="flex items-center gap-2">
          <img
            class="w-8 h-8 rounded"
            src={comment.author_avatar_urls[48]}
            alt={comment.author_name}
          />
          <div class="font-small D1:text-white">
            <div className=" text-gray-900 D1:text-white ">
              {" "}
              {comment.author_name}
            </div>
            <p className="text-sm text-gray-500 D1:text-gray-400 mb-0">
              <time pubdate datetime={comment.date} title={comment.date}>
                {new Date(comment.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </time>
            </p>
          </div>
        </div>

        {/* <Dropdown>
          <DropdownTrigger>
            <button>
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 3"
              >
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
            </button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Dropdown Actions" variant="flat">
            <DropdownItem key="delete" color="danger">
              Delete
            </DropdownItem>
            <DropdownItem key="edit">Edit</DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
      </footer>
      <p
        className={` ${styles.commentText} text-gray-500 D1:text-gray-400 mb-2`}
        dangerouslySetInnerHTML={{ __html: comment.content.rendered }}
      />

      <div className="flex items-center space-x-4 mb-4">
        {user?.is_active ? (
          <button
            className="flex items-center text-sm text-gray-800 hover:underline D1:text-gray-400"
            onClick={() => setReplyTo(comment.id)} // Set replyTo to the comment ID
          >
            <svg
              className="mr-1.5 w-3.5 h-3.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
              />
            </svg>
            Reply
          </button>
        ) : null}
      </div>

      {/* Render reply form under the comment */}
      {replyTo === comment.id && renderReplyForm()}

      {comment.replies.length > 0 && (
        <div className="mt-4 space-y-4">
          {comment.replies.map(renderComment)}
        </div>
      )}
    </article>
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="mb-4 text-lg font-semibold text-gray-900 D1:text-white ">
        Comments ({comments.length})
      </h2>
      {user?.is_active === false &&
        emailVerificationWarning({
          color:
            "text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50",
          text: "Your account is not verified. Please verify your account before commenting.",
        })}
      {!user?.username && isAuthenticated && user?.is_active && (
        <UpdateUsernameForm />
      )}
      {renderReplyForm()}
      {comments.length > 0 ? (
        <div className="space-y-4">{comments.map(renderComment)}</div>
      ) : (
        <p>No comments yet.</p>
      )}
    </section>
  );
};

export default Comments;

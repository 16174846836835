import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { login, googleLogin } from "../../../redux/slice/authSlice";
import GoogleLoginButton from "../GoogleLoginButton";
import { clearError } from "../../../redux/slice/authSlice";

function SignIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated, errorType } = useSelector(
    (state) => state.auth
  );

  const validateEmail = (email) => {
    if (!email || !email.trim()) {
      return "Email is required";
    }

    email = email.trim();

    if (!email.includes("@")) {
      return "Email must contain an @ symbol";
    }

    const [localPart, ...domainParts] = email.split("@");
    const domainPart = domainParts.join("@");

    if (domainParts.length > 1) {
      return "Email must contain exactly one @ symbol";
    }

    if (!localPart) {
      return "Username part before @ cannot be empty";
    }

    if (/^[.]|[.]$/.test(localPart)) {
      return "Username cannot start or end with a dot";
    }

    if (/[.]{2,}/.test(localPart)) {
      return "Username cannot contain consecutive dots";
    }

    if (!/^[a-zA-Z0-9._-]+$/.test(localPart)) {
      return "Username can only contain letters, numbers, dots, hyphens, and underscores";
    }

    if (!domainPart) {
      return "Domain part after @ cannot be empty";
    }

    if (/^[.-]|[.-]$/.test(domainPart)) {
      return "Domain cannot start or end with a dot or hyphen";
    }

    if (!/^[a-zA-Z0-9.-]+$/.test(domainPart)) {
      return "Domain can only contain letters, numbers, dots, and hyphens";
    }

    if (!/[.][a-zA-Z]{2,}$/.test(domainPart)) {
      return "Domain must end with a valid TLD (e.g., .com, .org, .net)";
    }

    if (/[.]{2,}/.test(domainPart)) {
      return "Domain cannot contain consecutive dots";
    }

    const domainLabels = domainPart.split(".");
    if (domainLabels.length < 2) {
      return "Domain must contain at least one dot";
    }

    if (domainLabels.some((label) => label.length === 0)) {
      return "Domain cannot have empty parts between dots";
    }

    return "";
  };

  const validateForm = () => {
    const emailError = validateEmail(formData.email);
    const passwordError = !formData.password.trim()
      ? "Password is required"
      : "";

    setValidationErrors({
      email: emailError,
      password: passwordError,
    });

    return !emailError && !passwordError;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear redux error when user starts typing
    dispatch(clearError());

    // Validate on change if the field has been touched
    if (touched[name]) {
      if (name === "email") {
        setValidationErrors((prev) => ({
          ...prev,
          email: validateEmail(value),
        }));
      } else if (name === "password" && !value.trim()) {
        setValidationErrors((prev) => ({
          ...prev,
          password: "Password is required",
        }));
      }
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    // Validate on blur
    if (name === "email") {
      setValidationErrors((prev) => ({
        ...prev,
        email: validateEmail(formData.email),
      }));
    } else if (name === "password" && !formData.password.trim()) {
      setValidationErrors((prev) => ({
        ...prev,
        password: "Password is required",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Set all fields as touched
    setTouched({
      email: true,
      password: true,
    });

    // Validate all fields before submission
    if (validateForm()) {
      await dispatch(
        login({
          email: formData.email,
          password: formData.password,
        })
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    } else {
      setResponseError(error);
    }
  }, [handleSubmit, navigate, isAuthenticated, error]);

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  return (
    <section className="bg-gray-50 D1:bg-gray-900">
      <div className="flex flex-col items-center mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8">
          <div className="flex justify-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold">
              Sign in to your account
            </h1>
          </div>

          <div className="">
            <GoogleLoginButton setResponseError={setResponseError} />
          </div>

          <form onSubmit={handleSubmit} className="w-full flex-1 mt-2">
            <div className="my-5 border-b text-center">
              <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                Or sign In with e-mail
              </div>
            </div>

            <div className="mx-auto max-w-xs">
              <div className="mb-4">
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 ${
                    touched.email && validationErrors.email
                      ? "border-red-500"
                      : "border-gray-200"
                  }`}
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  name="email"
                  required
                />
                {touched.email && validationErrors.email && (
                  <p className="mt-1 text-xs text-red-600">
                    {validationErrors.email}
                  </p>
                )}
              </div>

              <div className="relative mb-4">
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${
                    touched.password && validationErrors.password
                      ? "border-red-500"
                      : "border-gray-200"
                  }`}
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                  value={formData.password}
                  name="password"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? (
                    <VisibilityOffTwoToneIcon />
                  ) : (
                    <VisibilityTwoToneIcon />
                  )}
                </button>
                {touched.password && validationErrors.password && (
                  <p className="mt-1 text-xs text-red-600">
                    {validationErrors.password}
                  </p>
                )}
              </div>

              {errorType !== "google_error" && error && (
                <p className="mt-2 text-xs text-red-600 D1:text-red-400">
                  {error}
                </p>
              )}

              <Link
                to="/forgot-password"
                className="text-sm font-medium text-primary-600 hover:underline D1:text-primary-500"
              >
                Forgot password?
              </Link>

              <button
                className="mt-5 tracking-wide font-semibold bg-blue-700 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                type="submit"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Signing...
                  </>
                ) : (
                  <>
                    <svg
                      className="w-6 h-6 -ml-2"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                      <circle cx="8.5" cy="7" r="4" />
                      <path d="M20 8v6M23 11h-6" />
                    </svg>
                    <span className="ml-3">Sign In</span>
                  </>
                )}
              </button>

              <p className="mt-4 text-sm font-light text-gray-500 D1:text-gray-400">
                Not registered?{" "}
                <Link
                  to="/signup"
                  className="font-medium text-primary-600 hover:underline D1:text-primary-500"
                >
                  Create an account.
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SignIn;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import {
  register,
  clearRegistrationSuccess,
  clearError,
} from "../../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleLoginButton from "../GoogleLoginButton";

const passwordValidations = {
  hasUpperCase: (password) => /[A-Z]/.test(password),
  hasNumber: (password) => /[0-9]/.test(password),
  hasMinLength: (password) => password.length >= 8,
  hasSpecialChar: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
};

const validatePassword = (password) => {
  const validations = {
    uppercase: !passwordValidations.hasUpperCase(password)
      ? "Password must contain at least one uppercase character"
      : "",
    number: !passwordValidations.hasNumber(password)
      ? "Password must contain at least one number"
      : "",
    length: !passwordValidations.hasMinLength(password)
      ? "Password must be at least 8 characters long"
      : "",
    special: !passwordValidations.hasSpecialChar(password)
      ? "Password must contain at least one special character"
      : "",
  };

  // Return first error message found, or empty string if all valid
  return Object.values(validations).find((error) => error) || "";
};

const validateEmail = (email) => {
  // Check if email is empty or only whitespace
  if (!email || !email.trim()) {
    return "Email is required";
  }

  // Trim the email to remove any leading/trailing whitespace
  email = email.trim();

  // Check minimum and maximum length

  // Check for @ symbol
  if (!email.includes("@")) {
    return "Email must contain an @ symbol";
  }

  // Split email into local and domain parts
  const [localPart, ...domainParts] = email.split("@");
  const domainPart = domainParts.join("@");

  // Check if multiple @ symbols
  if (domainParts.length > 1) {
    return "Email must contain exactly one @ symbol";
  }

  // Check local part (before @)
  if (!localPart) {
    return "Username part before @ cannot be empty";
  }

  if (/^[.]|[.]$/.test(localPart)) {
    return "Username cannot start or end with a dot";
  }
  if (/[.]{2,}/.test(localPart)) {
    return "Username cannot contain consecutive dots";
  }
  if (!/^[a-zA-Z0-9._-]+$/.test(localPart)) {
    return "Username can only contain letters, numbers, dots, hyphens, and underscores";
  }

  // Check domain part (after @)
  if (!domainPart) {
    return "Domain part after @ cannot be empty";
  }
  if (/^[.-]|[.-]$/.test(domainPart)) {
    return "Domain cannot start or end with a dot or hyphen";
  }
  if (!/^[a-zA-Z0-9.-]+$/.test(domainPart)) {
    return "Domain can only contain letters, numbers, dots, and hyphens";
  }
  if (!/[.][a-zA-Z]{2,}$/.test(domainPart)) {
    return "Domain must end with a valid TLD (e.g., .com, .org, .net)";
  }
  if (/[.]{2,}/.test(domainPart)) {
    return "Domain cannot contain consecutive dots";
  }

  // Check total number of dots in domain
  const domainLabels = domainPart.split(".");
  if (domainLabels.length < 2) {
    return "Domain must contain at least one dot";
  }
  if (domainLabels.some((label) => label.length === 0)) {
    return "Domain cannot have empty parts between dots";
  }

  // If all checks pass, return empty string (valid email)
  return "";
};

// Example usage:
/*
console.log(validateEmail("")); // "Email is required"
console.log(validateEmail("test")); // "Email must contain an @ symbol"
console.log(validateEmail("test@")); // "Domain part after @ cannot be empty"
console.log(validateEmail("@domain.com")); // "Username part before @ cannot be empty"
console.log(validateEmail("test@domain")); // "Domain must end with a valid TLD (e.g., .com, .org, .net)"
console.log(validateEmail("test..name@domain.com")); // "Username cannot contain consecutive dots"
console.log(validateEmail("test@domain..com")); // "Domain cannot contain consecutive dots"
console.log(validateEmail("test@domain.c")); // "Domain must end with a valid TLD (e.g., .com, .org, .net)"
console.log(validateEmail("valid.email@domain.com")); // ""
*/

const validateUsername = (username) => {
  return !username.trim() ? "Username is required" : "";
};

function SignUp() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
    // username: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { registrationSuccess, error, loading, isAuthenticated, errorType } =
    useSelector((state) => state.auth);

  useEffect(() => {
    // Clear error on component mount
    dispatch(clearError());
  }, [dispatch]);

  // Validate entire form
  const isFormValid = () => {
    const validations = {
      email: validateEmail(formData.email),
      password: validatePassword(formData.password),
    };

    setErrors(validations);
    return !Object.values(validations).some((error) => error);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear response error when user types

    dispatch(clearError());

    // Validate field if it's been touched
    if (touched[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]:
          name === "email" ? validateEmail(value) : validatePassword(value),
        // : validateUsername(value),
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]:
        name === "email"
          ? validateEmail(formData.email)
          : validatePassword(formData.password),
      // : validateUsername(formData.username),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mark all fields as touched
    setTouched({
      email: true,
      password: true,
      // username: true,
    });

    // Validate all fields before submission
    if (isFormValid()) {
      try {
        await dispatch(register(formData));
      } catch (err) {
        setResponseError(err.message);
      }
    }
  };

  // Handle successful registration
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/select-banks", { replace: true });
      dispatch(clearRegistrationSuccess());
    } else if (error) {
      setResponseError(error);
    }

    const can_submit = () => {
      const validations = {
        email: validateEmail(formData.email),
        password: validatePassword(formData.password),
      };

      return !Object.values(validations).some((error) => error);
    };
    setCanSubmit(can_submit);
  }, [
    registrationSuccess,
    error,
    navigate,
    dispatch,
    handleSubmit,
    handleChange,
  ]);

  // Check if password meets all requirements
  const passwordStrength = {
    uppercase: passwordValidations.hasUpperCase(formData.password),
    number: passwordValidations.hasNumber(formData.password),
    length: passwordValidations.hasMinLength(formData.password),
    special: passwordValidations.hasSpecialChar(formData.password),
  };

  // Determine if form can be submitted
  // const canSubmit =
  //   !errors.email &&
  //   !errors.password &&
  //   !errors.username &&
  //   Object.values(passwordStrength).every(Boolean) &&
  //   Object.values(touched).every(Boolean);

  return (
    <section class="bg-gray-50 D1:bg-gray-900">
      <div class="flex flex-col items-center mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* <div>
            <img
              src="https://storage.googleapis.com/devitary-image-host.appspot.com/15846435184459982716-LogoMakr_7POjrN.png"
              className="w-32 mx-auto"
            />
          </div> */}
        <div className="w-full  bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8">
          <div className="flex justify-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold ">
              Create an account
            </h1>
          </div>
          <div className="">
            <GoogleLoginButton setResponseError={setResponseError} />
          </div>
          <form onSubmit={handleSubmit} className="w-full flex-1 mt-3">
            {/* <div className="flex flex-col items-center">
              <button className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
                <div className="bg-white p-2 rounded-full">
                  <svg className="w-4" viewBox="0 0 533.5 544.3">
                    <path
                      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                      fill="#4285f4"
                    />
                    <path
                      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                      fill="#34a853"
                    />
                    <path
                      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                      fill="#fbbc04"
                    />
                    <path
                      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                      fill="#ea4335"
                    />
                  </svg>
                </div>
                <span className="ml-4">Sign Up with Google</span>
              </button>
            </div> */}

            <div className="my-5 mt-1 border-b text-center">
              <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                Or sign up with e-mail
              </div>
            </div>

            <div className="mx-auto max-w-xs">
              {/* <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Full Name"
              /> */}
              {/* <div className="relative mb-0">
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                    touched.username && errors.username
                      ? "border-red-500"
                      : "border-gray-200"
                  } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5`}
                  type="text"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder="username"
                  name="username"
                  required
                  onBlur={handleBlur}
                />
                <p
                  id="standard_error_help"
                  class="mt-2 text-xs text-red-600 D1:text-red-400"
                >
                  {errors.username}
                </p>
              </div> */}
              <div className="relative mt-0">
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                    touched.email && errors.email
                      ? "border-red-500"
                      : "border-gray-200"
                  } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white `}
                  type="email"
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="Email"
                  name="email"
                  required
                  onBlur={handleBlur}
                />
                <p
                  id="standard_error_help"
                  class="mt-2 text-xs text-red-600 D1:text-red-400"
                >
                  {errors.email}
                </p>
              </div>
              <div className="relative mt-5">
                <input
                  className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                    touched.password && errors.password
                      ? "border-red-500"
                      : "border-gray-200"
                  } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  placeholder="Password"
                  value={formData.password}
                  required
                  name="password"
                  onBlur={handleBlur}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOffTwoToneIcon />
                  ) : (
                    <VisibilityTwoToneIcon />
                  )}
                </button>
              </div>
              <p
                id="standard_error_help"
                class="mt-2 text-xs text-red-600 D1:text-red-400"
              >
                {errors.password || (errorType !== "google_error" && error)}
              </p>
              {/* <input
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                /> */}
              {/* <p
                id="standard_error_help"
                class="mt-2 text-xs text-red-600 D1:text-red-400"
              >
                {error || responseError}
              </p> */}
              {/* Update password requirements list to show real-time validation */}
              <div className="space-y-1 text-sm text-gray-500">
                <p className="text-sm mt-5">Password must contain:</p>
                <ul className="list-disc list-inside space-y-1">
                  <li
                    className={
                      passwordStrength.uppercase ? "text-green-600" : ""
                    }
                  >
                    One uppercase character {passwordStrength.uppercase && "✓"}
                  </li>
                  <li
                    className={passwordStrength.number ? "text-green-600" : ""}
                  >
                    One number {passwordStrength.number && "✓"}
                  </li>
                  <li
                    className={passwordStrength.length ? "text-green-600" : ""}
                  >
                    Minimum 8 characters {passwordStrength.length && "✓"}
                  </li>
                  <li
                    className={passwordStrength.special ? "text-green-600" : ""}
                  >
                    One special character {passwordStrength.special && "✓"}
                  </li>
                </ul>
              </div>

              {/* Update the submit button to be disabled when form is invalid */}
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!canSubmit}
                className={`mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none
          ${
            canSubmit
              ? "bg-blue-700 text-gray-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Signing...
                  </>
                ) : (
                  <>
                    <svg
                      className="w-6 h-6 -ml-2"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                      <circle cx="8.5" cy="7" r="4" />
                      <path d="M20 8v6M23 11h-6" />
                    </svg>
                    <span className="ml-3">Sign Up</span>
                  </>
                )}
              </button>
              {/* <p className="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by templatana's
                  <a
                    href="#"
                    className="border-b border-gray-500 border-dotted"
                  >
                    Terms of Service
                  </a>
                  and its
                  <a
                    href="#"
                    className="border-b border-gray-500 border-dotted"
                  >
                    Privacy Policy
                  </a>
                </p> */}
              <p class=" mt-4 text-sm font-light text-gray-500 D1:text-gray-400">
                Already have an account?{" "}
                <Link
                  to={"/signin"}
                  className="  font-medium text-primary-600 hover:underline D1:text-primary-500"
                >
                  Login here.
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SignUp;

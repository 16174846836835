import React, { useState, useEffect } from "react";
import { Search, X } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../redux/slice/authSlice";
import { updateBankPreferences } from "./bankPreferences";

const BankSelectionModal = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const bankPreferences = useSelector(
    (state) => state.auth.user?.bank_preferences
  );
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const bankName = useSelector((state) => state.fdRateData.data1.top_fd);
  const email = useSelector((state) => state.auth.user?.email);
  const navigate = useNavigate();
  const API_URL = "https://backend.wanij.com/";

  useEffect(() => {
    if (bankPreferences && bankName) {
      const initialSelectedBanks = Object.values(bankPreferences).map(
        (bank) => ({
          name: bank.name,
          bref: bank.bref,
          bcode: bank.bcode,
          type: bank.type,
          image: "../bankLogos/" + bank.bcode + ".png",
        })
      );
      setSelectedBanks(initialSelectedBanks);
    }
  }, [bankPreferences, bankName]);

  const filteredBanks = bankName.filter(
    (bank) =>
      bank.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bank.bref?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  filteredBanks.sort((a, b) => a.name.localeCompare(b.name));

  const handleBankSelection = (bank) => {
    if (selectedBanks.some((b) => b.bref === bank.bref)) {
      setSelectedBanks(selectedBanks.filter((b) => b.bref !== bank.bref));
    } else if (selectedBanks.length < 5) {
      setSelectedBanks([...selectedBanks, bank]);
    }
  };

  const handleRemoveBanks = () => {
    setSearchQuery("");
  };

  const handleUpdatePreferences = async () => {
    try {
      await updateBankPreferences({
        selectedBanks,
        email,
        apiUrl: API_URL,
        onSuccess: async () => {
          const userDetails = await dispatch(getUserDetails());
          navigate("/user-account");
        },
        onError: (error) => setError(error),
        onLoadingChange: (isLoading) => setLoading(isLoading),
      });
    } catch (err) {
      // Handle any additional error handling if needed
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-4xl mx-auto px-4 py-16 mt-5">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Select Your Banks</h1>
          <p className="text-gray-600 text-xl">
            Choose up to 5 banks to receive notifications about the latest FD
            and SD rates
          </p>
        </div>

        {/* Search Bar */}
        <div className="relative mb-8 max-w-xl mx-auto">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-200 focus:outline-none focus:border-gray-400 text-lg"
            placeholder="Search banks..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={handleRemoveBanks}>
            <X className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
          </button>
        </div>

        {/* Banks Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-5">
          {filteredBanks.map((bank) => {
            const bankSelection = {
              name: bank.name,
              bref: bank.bref,
              bcode: bank.bcode,
              type: bank.type,
              image: "../bankLogos/" + bank.bcode + ".png",
            };
            const isSelected = selectedBanks.some(
              (b) => b.bref === bankSelection.bref
            );
            const isDisabled = selectedBanks.length >= 5 && !isSelected;

            return (
              <div
                key={bank.name}
                onClick={() =>
                  !isDisabled && handleBankSelection(bankSelection)
                }
                className={`h-12 w-full inline-flex items-center px-3 rounded-full
                  transition-all duration-200 ${
                    isSelected
                      ? "bg-green-50 text-green-600 border border-green-600"
                      : "bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100"
                  } ${
                  isDisabled
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                }`}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center flex-shrink-0">
                    <img
                      className="w-6 h-6 rounded"
                      src={`../bankLogos/${bank.bcode}.png`}
                      alt={bank.name}
                    />
                    <span className="ml-2 text-sm font-medium truncate max-w-[100px]">
                      {bank.mobile_name || bank.name}
                    </span>
                  </div>
                  {isSelected && (
                    <svg
                      className="w-5 h-5 flex-shrink-0 ml-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Fixed Footer */}
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3">
          <div className="max-w-3xl mx-auto flex items-center justify-between">
            <span className="text-lg text-gray-600">
              {selectedBanks.length} of 5 banks selected
            </span>
            <button
              onClick={handleUpdatePreferences}
              disabled={selectedBanks.length === 0 || loading}
              className={`px-8 py-3 rounded-full font-medium text-lg transition-all duration-200
                ${
                  selectedBanks.length > 0
                    ? "bg-black text-white hover:bg-gray-800"
                    : "bg-gray-100 text-gray-400 cursor-not-allowed"
                }`}
            >
              {loading ? "Saving..." : "Continue"}
            </button>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="fixed top-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded-lg">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default BankSelectionModal;

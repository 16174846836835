import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";

function Breadcrumbs({ color }) {
  // Define custom routes with their display names
  const { id } = useParams();
  const routes = [
    { path: "/", breadcrumb: "Home" },
    { path: "/bankDetails", breadcrumb: "Fixed Deposits" },
    { path: "/savingsRateDetails", breadcrumb: "Savings Deposits" },
    { path: "/FDpage", breadcrumb: "Fixed Deposits" },
    { path: "/savingsPage", breadcrumb: "Savings Deposits" },
    { path: "/content", breadcrumb: "Learning Centre" },
    { path: "/contactUs", breadcrumb: "Contact Us" },
    { path: "/privacy", breadcrumb: "Privacy Policy" },
    { path: "/tandC", breadcrumb: "Terms & Conditions" },
    { path: "/blog", breadcrumb: "Blog" },

    // Add more routes with custom names as needed
    {
      path: "blog/:id", // For paths with tokens
      breadcrumb: "Blog details", // Hide token paths from breadcrumbs
    },
    {
      path: "/verify-email/:token", // For paths with tokens
      breadcrumb: null, // Hide token paths from breadcrumbs
    },
    {
      path: "/forgot-password/:uid/:token", // For paths with tokens
      breadcrumb: "Forget password", // Hide token paths from breadcrumbs
    },
  ];

  // Custom function to get redirect path
  const getRedirectPath = (pathname) => {
    switch (pathname) {
      case "/bankDetails":
        return "/FDpage";
      case "/savingsRateDetails":
        return "/savingsPage";
      case "/verify-email":
        return "/verify-email/id";
      default:
        return pathname;
    }
  };

  // Use the custom routes configuration
  const breadcrumbs = useBreadcrumbs(routes);
  // console.log("breadcrumbs", breadcrumbs);

  // Filter out null breadcrumbs (token paths)
  const filteredBreadcrumbs = breadcrumbs.filter(
    ({ breadcrumb }) => breadcrumb !== null
  );

  return (
    <div
      className={styles.breadcrumbsContainer}
      style={{ backgroundColor: color }}
    >
      <div className={styles.breadcrumbSubCont}>
        <div className={styles.breadcrumbSubDiv}>
          {filteredBreadcrumbs.map(({ breadcrumb, match }, index) => {
            // Skip rendering if it's a token path
            if (match.pathname.includes("token")) return null;

            const redirectPath = getRedirectPath(match.pathname);
            const isActive = match.pathname === window.location.pathname;

            return (
              <React.Fragment key={match.pathname}>
                <Link
                  to={redirectPath}
                  className={
                    isActive ? styles.breadcrumbActive : styles.breadcrumb
                  }
                >
                  {index > 0 && (
                    <NavigateNextIcon
                      sx={{
                        color: "#5f6368",
                        marginRight: "5px",
                        fontSize: 17,
                      }}
                    />
                  )}
                  <span>
                    {breadcrumb.props.children.replaceAll("%20", " ")}
                  </span>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Breadcrumbs;

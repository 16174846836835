import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateUsername } from "../../../utils/updateUsername";
import { getUserDetails } from "../../../redux/slice/authSlice";

const UpdateUsernameForm = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Assuming you store the token in Redux state
  const email = useSelector((state) => state.auth.user?.email);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setSuccess(false);

    try {
      const response = await axios.post(
        "https://backend.wanij.com/auth/add-username/",
        { username, email }
      );
      setSuccess(true);
      dispatch(getUserDetails());
      setUsername("");
      return response.data;
    } catch (error) {
      //   Update user data in Redux store if needed

      setError(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  //   console.log("error", error);
  //   console.log("success", success);

  //   {error && (
  //     <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">{error}</div>
  //   )}

  //   {success && (
  //     <div className="mb-4 p-3 bg-green-100 text-green-700 rounded">
  //       Username updated successfully!
  //     </div>
  //   )}

  return (
    <div className="py-2 pb-2 pt-6 px-4 my-4 bg-white rounded-lg rounded-t-lg border border-gray-200 D1:bg-gray-800 D1:border-gray-700">
      <form class="flex  max-w-lg mx-auto ">
        <label for="voice-search" class="sr-only">
          Search
        </label>
        <div class="relative w-full">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-6 h-6 text-gray-800 D:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="username"
            value={username}
            minLength={3}
            disabled={loading}
            onChange={(e) => setUsername(e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  D:bg-gray-700 D:border-gray-600 D:placeholder-gray-400 D:text-white D:focus:ring-blue-500 D:focus:border-blue-500"
            placeholder="Enter your username..."
            required
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          class={`inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 D:bg-blue-600 D:hover:bg-blue-700 D:focus:ring-blue-800 
          ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          {loading ? "Updating..." : "submit"}
        </button>
      </form>
      {success ? (
        <div
          class="flex items-center max-w-lg mx-auto p-2 mb-4 mt-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 D:bg-gray-800 D:text-green-400 D:border-green-800"
          role="alert"
        >
          <svg
            class="flex-shrink-0 inline w-4 h-4 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span class="sr-only">Info</span>
          <div>
            <span class="font-medium">Username updated successfully!</span>{" "}
          </div>
        </div>
      ) : error ? (
        <div
          class="flex items-center max-w-lg mx-auto p-2 mb-4 mt-4 text-red-800 border border-red-300 rounded-lg bg-red-50 D:bg-gray-800 D:text-red-400 D:border-red-800"
          role="alert"
        >
          <svg
            class="flex-shrink-0 inline w-4 h-4 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span class="sr-only">Info</span>
          <div>
            <span class="font-medium">{error}</span>.
          </div>
        </div>
      ) : (
        <div
          class="flex items-center max-w-lg mx-auto p-2 mb-4 mt-4 text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 D:bg-gray-800 D:text-yellow-300 D:border-yellow-800"
          role="alert"
        >
          <svg
            class="flex-shrink-0 inline w-4 h-4 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span class="sr-only">Info</span>
          <div>
            <span class="font-medium">Enter your username</span> to continue
            with comments.
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateUsernameForm;
